import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/calendar/api/api";
import {
  DetailsMeetings,
  Meeting,
  MyEvent,
  ResponseDetailsMeetings,
  ResponseGetMeeting,
} from "pages/calendar/components/calendar/model";
import moment from "moment";
import { formatDate } from "helpers/formatDate";

type InitialState = {
  showModalHours: boolean;
  currentIdEvent: number;
  isReloadNeeded: boolean;
  isFeching: boolean;
  dataMeetings: Meeting[];
  events: MyEvent[];
  eventSelected?: MyEvent;
  dateSelected: string;
  showModalDescriptionMeeting: boolean;
  showModalDeleteMeeting: boolean;
  dataDetailsMeeting?: DetailsMeetings;
};
const initialState: InitialState = {
  showModalHours: false,
  currentIdEvent: 1,
  isReloadNeeded: true,
  isFeching: false,
  dataMeetings: [],
  events: [],
  eventSelected: undefined,
  dateSelected: "",
  showModalDescriptionMeeting: false,
  showModalDeleteMeeting: false,
  dataDetailsMeeting: undefined,
};
export const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setOpenModalHours: (state, action: PayloadAction<boolean>) => {
      state.showModalHours = action.payload;
    },
    setCurrentIdAddEvent: (state, action: PayloadAction<number>) => {
      state.currentIdEvent = action.payload;
    },
    setCalendarInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setCalendarSucess: (state, action: PayloadAction<Meeting[]>) => {
      state.dataMeetings = action.payload.filter(
        (item) => item.meeting_end_date
      );

      const newDataMyEvent: MyEvent[] = [];

      action.payload.forEach((item) => {
        if (item.meeting_end_date) {
          const event = {
            id: item.uid,
            title: item.meeting_name,
            start: new Date(
              moment(item.meeting_start_date).add(5, "hours").toISOString()
            ),
            end: new Date(
              moment(item.meeting_end_date).add(5, "hours").toISOString()
            ),
          };

          newDataMyEvent.push(event);
        }
      });

      state.events = newDataMyEvent;
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setCalendarError: (state) => {
      state.isReloadNeeded = false;
      state.isFeching = false;
    },
    setdateSelected: (state, action: PayloadAction<string>) => {
      state.dateSelected = formatDate(
        moment(action.payload).add(1, "day").toISOString()
      );
    },
    setshowModalDeleteMeeting: (state, action: PayloadAction<boolean>) => {
      state.showModalDeleteMeeting = action.payload;
    },
    setSelectEvent: (state, action: PayloadAction<MyEvent>) => {
      state.eventSelected = action.payload;
    },
    setshowModalDescriptionMeeting: (state, action: PayloadAction<boolean>) => {
      state.showModalDescriptionMeeting = action.payload;
    },
    setDataDetailsMeetingsucess: (
      state,
      action: PayloadAction<DetailsMeetings>
    ) => {
      state.dataDetailsMeeting = action.payload;
    },
    setReloadCalendar: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = false;
    },
    resetCalendar: (state) => {
      state.dataDetailsMeeting = undefined;
      state.currentIdEvent = 1;
    },
  },
});
export const {
  setOpenModalHours,
  setCurrentIdAddEvent,
  setdateSelected,
  setReloadCalendar,
  setshowModalDeleteMeeting,
  setSelectEvent,
  setshowModalDescriptionMeeting,
  resetCalendar,
} = slice.actions;

export default slice.reducer;

export const getDataMetetings = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  try {
    dispatch(slice.actions.setCalendarInit());
    const { token, collaborator } = getState().auth.dataUser!;
    let response: ResponseGetMeeting
    if (collaborator.is_admin) {
      response = await Api.getDataMeetingsCompany(token);
    }
    else {
      response = await Api.getDataMeetings(token);
    }
    dispatch(slice.actions.setCalendarSucess(response?.meetings));
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    dispatch(removeProcess(idProcess));
    dispatch(slice.actions.setCalendarError());
    return false;
  }
};

export const getDataMetetingsById =
  (uid?: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        const { token } = getState().auth.dataUser!;
        const { id } = getState().calendar.calendar.eventSelected!;
        const response: ResponseDetailsMeetings = await Api.getDataMeetingById(
          id ? id : uid ? uid : "",
          token
        );
        dispatch(slice.actions.setDataDetailsMeetingsucess(response.meeting));
        dispatch(removeProcess(idProcess));
        return true;
      } catch (error: any) {
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const updateMeetingById =
  (comment: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        const { token } = getState().auth.dataUser!;
        const { id } = getState().calendar.calendar.eventSelected!;
        const response: ResponseDetailsMeetings = await Api.updateMeetingById(
          id,
          comment,
          token
        );
        dispatch(removeProcess(idProcess));
        return response;
      } catch (error: any) {
        dispatch(removeProcess(idProcess));
        return false;
      }
    };
