import Title from "components/fcdt-title/fcdt-title";
import style from "../styles/body-follow-up.module.scss";
import PieChartBar from "../../custom-charts/pie-bar/pie-bar";
import { Data } from "react-minimal-pie-chart/types/commonTypes";
import GaugeChartSpeed from "../../custom-charts/gauge-chart/gauge-chart";
import BarPercentageChart from "../../custom-charts/bar-percentage/bar-percentage";
import TitleCharts from "../title-chars/title-charts";
import MediaPieChart from "../../custom-charts/media-pie-chart/media-pie-chart";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import ButtonFloatContainer from "../../button_float/button-float-container";

const MeetingPeopleCharts = () => {
  const { dataDashboard, colaboratorSelected, dataDashboardRecomendation } =
    useSelector((state: RootState) => {
      return state.followup;
    });

  if (!dataDashboard || !colaboratorSelected) return null;

  const dataEmotions: Data = dataDashboard.data_sentiment_chart
    ? dataDashboard.data_sentiment_chart.map((item) => {
        return {
          title: item.label,
          value: item.value * 100,
          color: item.color,
        };
      })
    : [];

  return (
    <div className={style.container_process_charts}>
      <TitleCharts
        title="Progreso de reuniones"
        subtitle={colaboratorSelected.label}
      />
      <section className={style.section_process}>
        <div className={style.container_two_chats_not_title}>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.attendance_percent_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.attendance_percent_chart_recommendation
                }
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Porcentajes de asistencia
            </Title>
            {dataDashboard.data_attendance_percent_chart ? (
              <BarPercentageChart
                percetage={Math.floor(
                  dataDashboard.data_attendance_percent_chart.percent * 100
                )}
                legend={true}
              />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.sentiment_chart_recommendation && (
              <ButtonFloatContainer
                data={dataDashboardRecomendation.sentiment_chart_recommendation}
              />
            )}
            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Estados emocionales
            </Title>
            <PieChartBar data={dataEmotions} itemLegentRectangular={true} />
          </div>
        </div>
      </section>
      <section className={style.section_process}>
        <div className={style.container_two_chats_not_title}>
          <div className={`${style.box_graph_shadown}`}>
            {dataDashboardRecomendation?.participation_percentage_chart_recommendation && (
              <ButtonFloatContainer
                data={
                  dataDashboardRecomendation.participation_percentage_chart_recommendation
                }
              />
            )}

            <Title fontSize={14} fontWeight={700} className={style.title_box}>
              Porcentaje de participación
            </Title>
            {dataDashboard.data_participation_percentage_chart ? (
              <MediaPieChart
                percentage={
                  dataDashboard.data_participation_percentage_chart.percent *
                  100
                }
                legend={true}
              />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
          <div className={style.box_graph_shadown}>
            {dataDashboardRecomendation?.wpm_chart_recommendation && (
              <ButtonFloatContainer
                data={dataDashboardRecomendation.wpm_chart_recommendation}
              />
            )}

            <Title fontSize={17} fontWeight={700} className={style.title_box}>
              Promedio de palabras por minuto
            </Title>
            {dataDashboard.data_wpm_chart ? (
              <GaugeChartSpeed
                speed={dataDashboard.data_wpm_chart.wpm}
                percentage={dataDashboard.data_wpm_chart.percent}
              />
            ) : (
              <Paragraph>Sin datos</Paragraph>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MeetingPeopleCharts;
