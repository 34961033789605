import { InfoFromTask } from "pages/document-management/components/reports/model";

export const formatGanttData = (data: InfoFromTask[]) => {
  const rows: any = data.map((item) => {
    const task_start_date = new Date(item.task_start_date);
    const task_due_date = new Date(item.task_due_date);
    const data = [
      item.task_name.replace(/\s/g, ""),
      item.task_name,
      null,
      new Date(task_start_date.getFullYear(), task_start_date.getMonth(), task_start_date.getDate()),
      new Date(task_due_date.getFullYear(), task_due_date.getMonth(), task_due_date.getDate()),
      null,
      100,
      null,
    ];
    return data;
  });

  return rows;
};
