import { useDispatch, useSelector } from "react-redux";
import style from "../style/newgoal.module.scss";
import { IoMdClose } from "react-icons/io";
import Button from "components/buttons/fcdt-button";
import { useForm } from "react-hook-form";
import { RootState } from "store/store";
import { FormRegisterType } from "pages/register/model";
import { styled } from '@mui/material/styles';
import {
  registerLeader,
  setOpenNewLeader,
  setisOpenNewProcess,
  updatePhoto,
} from "pages/admin/store/slices/process";
import toast from "react-hot-toast";
import FCDTToggle from "components/fcdy-toggle/fcdt-toggle";
import { DataToggle } from "components/fcdy-toggle/model";
import { handleKeyDown, handleKeyDownPhone } from "helpers/keyDownNumber";
import { getUserFromCompanyWithOutReload } from "store/slices/users";
import { useState } from "react";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";
import { Button as ButtonMaterial } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type NewLeaderProps = {
  handleClose?: () => void;
  dataToggle?: DataToggle;
  handleChangeToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  user?: FormRegisterType;
  idAction?: number;
  updateUser?: boolean;
};

export const NewLeader = (props: NewLeaderProps) => {
  const { handleClose, dataToggle, handleChangeToggle, user, idAction, updateUser } = props;
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { register, handleSubmit, reset } = useForm<FormRegisterType>({
    defaultValues: user ? user : undefined
  });
  const [photoUser, setphotoUser] = useState()


  const dispatch = useDispatch<any>();
  const { isOpenNewProcess } = useSelector((state: RootState) => {
    return state.admin.process;
  });

  const [toggleActive, setToggleActive] = useState<boolean>(
    dataToggle ? dataToggle.active : true
  );

  const handleCloseDialog = () => {
    dispatch(setOpenNewLeader(false));
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
  };

  const onSubmit = async (data: FormRegisterType) => {
    const response = await dispatch(
      registerLeader({ ...data, status: toggleActive })
    );

    if (!response?.ok) {
      toast.error(response?.msg, { duration: 7000, position: "top-center" });
      return;
    }

    if (photoUser) {
      const responsePhoto = await dispatch(
        updatePhoto(photoUser)
      );
      if (!responsePhoto?.ok) {
        toast.error(response?.msg, { duration: 7000, position: "top-center" });
        return;
      }
    }
    dispatch(getUserFromCompanyWithOutReload());
    toast.success(response?.msg, { duration: 7000, position: "top-center" });
    handleCloseDialog();
    handleClose && handleClose();
    reset();
  };

  const handleChangeLoadImage = async (e: any) => {
    if (e.target.files[0].size < 1000000) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        toast.success("Foto seleccionada correctamente", { duration: 4000, position: "top-center" });
        setphotoUser(e.target.files[0])
      }
    };
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.new_goal_container}>
        <div className={style.header}>
          <h2>
            {idAction
              ? idAction === 1
                ? "Editar usuario"
                : idAction === 2
                  ? "Información de usuario"
                  : ""
              : "Nuevo usuario"}
          </h2>
          <button
            type="button"
            onClick={handleClose ? handleClose : handleCloseDialog}
          >
            <IoMdClose size={35} color="#2f0084" />{" "}
          </button>
        </div>
        <div className={style.content}>
          <div className={style.content_left}>
            <label htmlFor="name">Nombre completo</label>
            <input
              disabled={updateUser ? updateUser : false}
              type="text"
              className="fcdt-input"
              placeholder="Nombre completo"
              required
              minLength={6}
              maxLength={50}
              pattern={REG_EXP.FULL_NAME}
              title={TITLE_REG_EXP.FULL_NAME}
              {...register("name")}
            />
            <label htmlFor="id_identification_type">Tipo de identificación</label>
            <select
              {...register("id_identification_type")}
              className="fcdt-select"
              required
              defaultValue={1}
              disabled={updateUser ? updateUser : false}
            >
              <option value="" disabled selected>
                Tipo de identificación
              </option>
              <option value={1}>Cédula ciudadanía</option>
              <option value={2}>Cédula extranjería</option>
            </select>
            <label htmlFor="email">Correo</label>
            <input
              type="email"
              disabled={updateUser ? updateUser : false}
              placeholder="Correo"
              className="fcdt-input"
              required
              minLength={6}
              maxLength={50}
              pattern={REG_EXP.EMAIL}
              title={TITLE_REG_EXP.EMAIL}
              {...register("email")}
            />
          </div>
          <div className={style.content_right}>
            <label htmlFor="phone_number">Teléfono</label>
            <input
              type="tel"
              placeholder="Teléfono"
              className="fcdt-input"
              required
              minLength={8}
              maxLength={15}
              onKeyDownCapture={(e) => handleKeyDownPhone(e)}
              {...register("phone_number")}
            />
            <label htmlFor="identification_number">Numero de identificación</label>
            <input
              type="tel"
              placeholder="Numero de identificación"
              className="fcdt-input"
              min={8}
              maxLength={11}
              onKeyDownCapture={(e) => handleKeyDown(e)}
              required
              {...register("identification_number")}
            />

            {dataToggle && handleChangeToggle && !updateUser ? (
              <div
                className={style.container_toggle}
                style={
                  dataUser?.collaborator.uid === user?.uid
                    ? {
                      pointerEvents: "none",
                      opacity: 0.5,
                    }
                    : {}
                }
              >
                <FCDTToggle
                  data={dataToggle}
                  handleChange={(e) => setToggleActive(e.target.checked)}
                  width={120}
                  height={35}
                />
              </div>
            ) : null}
            <div>
              <ButtonMaterial
                sx={{ width: '1000px', height: '25px' }}
                component="label"
                role={undefined}
                startIcon={<CloudUploadIcon />}
              >
                Cargar foto
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleChangeLoadImage(e)}
                />
              </ButtonMaterial>
            </div>
          </div>
        </div>
        <div className={style.content_buttons}>
          <Button
            type="button"
            className="btn_cancel"
            children="Cancelar"
            width="40"
            onClick={handleClose ? handleClose : handleCloseDialog}
          />
          <Button children="Guardar" type="submit" width="40" />
        </div>
      </div>
    </form>
  );
};
