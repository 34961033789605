import React from "react";
import style from "./style/profile-nav.module.scss";
import { RootState, AppDispatch } from "store/store";
import Icon from "assets/backgroud_points.webp";
import { Button, Menu, MenuItem } from "@mui/material";
import { setOpenNewPassword } from "store/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { NewPassword } from "components/modals/components/new-password";
import Dialog from "components/modals/dialog";
import { APIURL } from "config/APIURL";
import { UsersTable } from "../../pages/users/model";
import { NewLeader } from "components/modals/components/new-leader";
import {
  getUserFromCompany,
  setIdActionUser,
  setShowModalNewUsers,
  setUserSelected,
} from "store/slices/users";

const ProfileNav = () => {
  const dispatch: AppDispatch = useDispatch();

  const { dataUser } = useSelector((state: RootState) => state.auth);
  const { showModalNewUsers, userSelected } = useSelector(
    (state: RootState) => state.users
  );

  const { isOpenNewPassword } = useSelector((state: RootState) => state.login);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const datauserupdate = dataUser?.collaborator;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(getUserFromCompany());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    dispatch(setOpenNewPassword(false));
  };

  const handleNewUser = () => {
    dispatch(setShowModalNewUsers(true));
  };

  const handleCloseNewUsers = () => {
    dispatch(setShowModalNewUsers(false));
  };

  const handleSelectUser = (user: UsersTable, idAction: number) => {
    dispatch(setShowModalNewUsers(true));
    dispatch(setUserSelected(user));
    dispatch(setIdActionUser(idAction));
  };

  const handleupdate = () => {
    if (datauserupdate) {
      handleClose();
      handleSelectUser(
        {
          id: datauserupdate.uid || "",
          name: datauserupdate.name_in_meeting_platform || "",
          email: datauserupdate.email || "",
        },
        1
      );
    }
  };

  const handleChangePassword = () => {
    dispatch(setOpenNewPassword(true));
    handleClose();
  };
  return (
    <div>
      <header className={style.profile}>
        <p>
          {dataUser?.collaborator
            ? dataUser.collaborator.name_in_meeting_platform
            : ""}
        </p>
        <Button onClick={handleClick}>
          {dataUser?.collaborator.photo ? <div>
            <img src={`${APIURL}/api/usuarios/uploads/${dataUser?.collaborator.photo}`} alt="Profile" width={52} height={52} />
          </div> : <img src={Icon} alt="Profile" width={52} height={52} />
          }
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem sx={{ marginTop: "5%" }} onClick={handleupdate}>
            Actualizar usuario
          </MenuItem>
          <MenuItem sx={{ marginTop: "5%" }} onClick={handleChangePassword}>
            Cambiar contraseña
          </MenuItem>
        </Menu>
      </header>
      <div style={{ padding: "10px" }}>
        <Dialog isOpen={isOpenNewPassword} onClose={handleCloseDialog}>
          <NewPassword isLogin={true} />
        </Dialog>
      </div>
      <Dialog
        isOpen={showModalNewUsers}
        onClose={handleNewUser}
        styleClose={false}
      >
        <NewLeader
          handleClose={handleCloseNewUsers}
          updateUser={true}
          user={userSelected}
          idAction={1}
        />
      </Dialog>
    </div>
  );
};

export default ProfileNav;
