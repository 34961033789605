import style from "./style/speech-tree.module.scss";
import { BiSolidRectangle } from "react-icons/bi";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import Button from "components/buttons/fcdt-button";
import { SpeechTreeProps } from "pages/document-management/model";
import { formatDate } from "helpers/formatDate";

const Speech = (props: SpeechTreeProps) => {
  const { dataSpeechTree, handleOpenDialog, handleNavigate } = props;

  const firstColumn = dataSpeechTree.collaborators.map((item) => {
    return item.name;
  });

  const secondColumn = dataSpeechTree.external_collaborators.map((item) => {
    return item.external_collaborator_email;
  });


  return (
    <section className={style.speech_tree_container}>
      <div className={style.content_data}>
        <h2>Árbol del discurso generado</h2>
        <p>
          <BiSolidRectangle size={30} color="#00de97" />
          {dataSpeechTree.meeting.meeting_name}
        </p>
        <p>
          <IoCalendarNumberOutline size={30} />
          Fecha de la reunion:{" "}
          {formatDate(dataSpeechTree.meeting.meeting_start_date)}
        </p>
        <p>
          <CiUser size={30} />
          Creador del evento: {dataSpeechTree.created_by}
        </p>
      </div>
      <hr />
      <h3>Participantes</h3>
      <div className={style.content_collaborators}>
        <div className={style.collaborators}>
          <ul className={style.column}>
            {firstColumn.length > 0 &&
              firstColumn.map((item) => <li key={item}>{item}</li>)}
          </ul>
        </div>
        <div className={style.collaborators} hidden={secondColumn.length === 0}>
          <ul className={style.column}>
            {secondColumn.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
     
      </div>
      <hr />
      <div className={style.actions}>
        <button
          type="button"
          onClick={() => handleOpenDialog(dataSpeechTree.meeting.uid)}
        >
          Ver árbol de discurso
        </button>
        {/* <div onClick={handleDownLoadSpeechTree}>
          <IoDownloadOutline size={30} color="#545252" />
          Descargar
        </div> */}
      </div>
      <div className={style.action_step}>
        <Button
          className="btn_cancel"
          children="Atrás"
          onClick={handleNavigate}
        />
        <Button onClick={handleNavigate}>Salir</Button>
      </div>
    </section>
  );
};

export default Speech;
