import axios from "axios";
import { APIURL} from "config/APIURL";

export const getDependencesKnowledge = async (
  idCompany: string | null,
  idProcess: string[] | null,
  idGoal: string[] | null,
  token: string
) => {
  const body = {
    microservice: "get_dependent_values_for_selectors",
    id_company: idCompany,
    id_process: idProcess,
    id_process_goal: idGoal,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/servicedocumet/consume_document_management_microservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getInformationKnowledge = async (
  idCompany: string,
  idsGoal: string[],
  idsCollaborators: string[],
  startDate: string | null,
  endDate: string | null,
  keywords: string | null,
  searchTerm: string | null,
  events: boolean[],
  token: string
) => {
  const body = {
    microservice: "make_knowledge_base_search",
    id_company: idCompany,
    ids_processes_goals: idsGoal,
    ids_collaborators: idsCollaborators,
    date_range: {
      start_date: startDate,
      end_date: endDate,
    },
    search_terms: searchTerm,
    keywords: keywords,
    events: {
      meetings: events[0],
      tasks: events[1],
      reports: events[2],
    },
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/servicedocumet/consume_document_management_microservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getSpeechTreeByIdMeeting = async (idMeeting: string, token: string) => {
  const body = {
    microservice: "extract_data_for_speech_tree_chart",
    id_associated_meeting: idMeeting,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/service/consume_event_management_dataservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
