import axios from "axios";
import { APIURL } from "config/APIURL";

import { RequestCreateTask } from "../model";

export const getProcessesName = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/process/get_collaborator_processes`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getColaboratorGolas = async (idProcess: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/process/${idProcess}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
// ---
export const getAllTaskByIdGoal = async (idGoal: string, token: string, authority: number, idUser: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/tasks/obtenerpormeta/${idGoal}/${authority}/${idUser}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
// ----
export const getTaskByuser = async (id_collaborator: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/taskresponsibles/tasks/${id_collaborator}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};


export const getAllColaborators = async (idGoal: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/process/get_collaborator_goal/${idGoal}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const createdTask = async (data: RequestCreateTask, token: string) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/tasks/crear`,
      headers: {
        "x-token": token,
      },
      data,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updatedTask = async (
  data: RequestCreateTask,
  idTask: string,
  idStatus: number,
  statusTask: boolean,
  token: string
) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/tasks/actualizar/${idTask}`,
      headers: {
        "x-token": token,
      },
      data: {
        ...data,
        execution_status: !statusTask ? 6 : idStatus === 6 ? 1 : idStatus,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getInformationTask = async (idTask: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/tasks/detalles/${idTask}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getInformationSubTask = async (idTask: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/taskchecklists/obtener_por_tarea/${idTask}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

// export const deleteTask = async (task: TasksByGoal, token: string) => {
//   try {
//     const body: RequestTask = {
//       assignated_by: task.assignated_by,
//       associated_goal: task.associated_goal,
//       task_priority: task.task_priority,
//       execution_status: 6,
//     };

//     const response = await axios({
//       method: "PUT",
//       url: `${APIURL}/api/tasks/actualizar/${task.uid}`,
//       headers: {
//         "x-token": token,
//       },
//       data: body,
//     });

//     return response?.data;
//   } catch (error: any) {
//     console.log(error.response.data);
//     return error.response?.data;
//   }
// };
