import axios from "axios";
import { APIURL } from "config/APIURL";

import {
  RequestEditDocument,
  ValuesFirstFormDocument,
  ValuesSecondFormDocument,
} from "../components/documents/components/edit-document/components/model";
// import { SpeechTreeTable } from "../model";

export const getAllDocuments = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/minutescontent`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getDocumentByID = async (idDocument: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/minutescontent/${idDocument}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const editDocument = async (
  firstForm: ValuesFirstFormDocument,
  secondForm: ValuesSecondFormDocument,
  idDocument: string,
  idMeeting: string,
  token: string
) => {
  const body: RequestEditDocument = {
    subject_discussion: firstForm.minuteDiscution,
    summary: secondForm.minutesSummay,
    commitments: secondForm.minutescommitment,
    conclusions: secondForm.minuteConclusion,
    id_associated_meeting: idMeeting,
    is_revised: true,
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/minutescontent/actualizar/${idDocument}`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const downloadDocument = async (idMeting: string, token: string) => {
  const body = {
    microservice: "export_minute_document",
    id_associated_meeting: idMeting,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/service/consume_event_management_dataservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};


export const get_generate_monitoring_report = async (created_by: string, token: string) => {
    try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/servicedocumet/get_generate_monitoring_report/${created_by}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
//////////////RECOMENDATION//////////

export const updateTaskRecomendation = async (
  idMetting: string,
  taskName:string,
  token: string,
  updateTask:boolean,
  oldTaskName:string
) => {
  const body = {
    microservice: "update_subtasks_from_minute",
    taskRecomended:taskName,
    id_meeting: idMetting,
    updateTask:updateTask,
    oldTaskName:oldTaskName
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/service/consume_event_management_dataservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getTaskRecomendation = async (
  idMetting: string,
  token: string
) => {
  const body = {
    microservice: "get_subtasks_from_minute",
    id_meeting: idMetting,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/service/consume_event_management_dataservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const setSubtaskIntoTask = async (
  idTask: string,
  subtask_description: string,
  token: string
) => {
  const body = {
    tasks: [idTask],
    checklist_description: subtask_description,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/taskchecklists/crear_por_tareas`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

//////////////SPEECH TREE////////////

export const getAllSpeechTree = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/speechtreessupplies/obtener_arboles_del_discurso_por_id_usuario`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getSpeechTreeByID = async (
  idSpeechTree: string,
  token: string
) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/meetings/obtener/${idSpeechTree}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getImageSpeechTree = async (idMeeting: string, token: string) => {
  const body = {
    microservice: "generate_speech_tree_chart",
    id_associated_meeting: idMeeting,
    speech_tree_figure_dir: "./temp_files",
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/service/consume_event_management_dataservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

// REPORTS

export const getServiceDocumentReport = async (
  idUser: string,
  idGoal: string,
  startDate: string,
  endDate: string,
  currentDate: string,
  token: string
) => {
  const body = {
    microservice: "organize_data_for_report",
    created_by: idUser,
    generation_date: `${currentDate}T00:00:00`,
    id_process_goal: idGoal,
    start_date: startDate + "T00:00:00",
    end_date: endDate + "T00:00:00",
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/servicedocumet/consume_document_management_microservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getGoalByID = async (idGoal: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/process/get_goal/${idGoal}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
