import axios from "axios";
import { APIURL } from "config/APIURL";
import { DataDashboard, DataDashboardRecomendation } from "../models";

export const getDataFollowUp = async (
  idProcess: string | null,
  idGoal: string | null,
  idCollaborator: string | null,
  eventsSelected: boolean[],
  idTaskByPriority: boolean,
  startDate: string | null,
  endDate: string | null,
  token: string
) => {
  const body = {
    microservice: "generate_dashboard_recommendations",
    id_process: idProcess,
    id_processes_goal: idGoal,
    id_collaborator: idCollaborator,
    events: {
      meetings: eventsSelected[0],
      tasks: eventsSelected[1],
    },
    stack_tasks_by_priority: idTaskByPriority,
    date_range: {
      start_date: startDate,
      end_date: endDate,
    },
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/servicemonitor/consume_monitoring_microservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};

export const generatePdfRecomendation = async (
  idProcess: string | null,
  idGoal: string | null,
  idCollaborator: string | null,
  eventsSelected: boolean[],
  idTaskByPriority: boolean,
  startDate: string | null,
  endDate: string | null,
  token: string,
  data?: DataDashboard,
  recommendations?: DataDashboardRecomendation
) => {
  const body = {
    microservice: "generate_recommendation_report",
    id_process: idProcess,
    id_processes_goal: idGoal,
    id_collaborator: idCollaborator,
    events: {
      meetings: eventsSelected[0],
      tasks: eventsSelected[1],
    },
    stack_tasks_by_priority: idTaskByPriority,
    date_range: {
      start_date: startDate,
      end_date: endDate,
    },
    data,
    recommendations,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/servicemonitor/consume_monitoring_microservice`,
      headers: {
        "x-token": token,
      },
      data: body,
    });
    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data;
  }
};
