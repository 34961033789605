import axios from "axios";
import { APIURL} from "config/APIURL";
import { FormRequestAddEvent } from "../components/create-event/model";

export const getDataMeetings = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/meetings/obtener_por_usuario`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
export const getDataMeetingsCompany = async (token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/meetings/obtener_por_empresa`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const createMeeting = async (
  form: FormRequestAddEvent,
  colaboratorSelected: string[],
  externalColaborators: string[],
  reviwer: string,
  idMeetingPlatform: number,
  token: string
) => {
  const body = {
    meeting_name: form.meetingName,
    meeting_description: form.meetingDescription,
    meeting_start_date: form.meetingStartDate,
    meeting_end_date: form.meetingEndDate,
    id_process_goal: form.idProcessGoal,
    minute_reviewer: reviwer,
    collaborators: colaboratorSelected,
    external_collaborators: externalColaborators,
    meeting_link: "",
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/meetings/crear/${idMeetingPlatform}`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const editMeeting = async (
  idMetting: string,
  form: FormRequestAddEvent,
  colaboratorSelected: string[],
  externalColaborators: string[],
  reviwer: string,
  idMeetingPlatform: number,
  token: string
) => {
  const body = {
    meeting_name: form.meetingName,
    meeting_description: form.meetingDescription,
    meeting_start_date: form.meetingStartDate,
    meeting_end_date: form.meetingEndDate,
    id_process_goal: form.idProcessGoal,
    minute_reviewer: reviwer,
    collaborators: colaboratorSelected,
    external_collaborators: externalColaborators,
    meeting_link: "",
    id_meeting_platform: idMeetingPlatform,
  };

  try {
    const response = await axios({
      method: "PUT",
      url: `${APIURL}/api/meetings/actualizar/${idMetting}`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const getDataMeetingById = async (idMeeting: string, token: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${APIURL}/api/meetings/obtener/${idMeeting}`,
      headers: {
        "x-token": token,
      },
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};

export const updateMeetingById = async (
  idMeeting: string,
  comment: string,
  token: string
) => {
  const body = {
    status: 2,
    comment: comment,
  };

  try {
    const response = await axios({
      method: "POST",
      url: `${APIURL}/api/meetings/update_meeting_status/${idMeeting}`,
      headers: {
        "x-token": token,
      },
      data: body,
    });

    return response?.data;
  } catch (error: any) {
    console.log(error.response.data);
    return error.response?.data;
  }
};
