import axios from "axios";
import { APIURL } from "config/APIURL";

import { FormContact } from "../model";

export const getDemoInformation = async (dataDemo: any) => {
  const formdata = new FormData();
  formdata.append("microservice", "obtain_demo_products");
  formdata.append("meeting_platform", `Microsoft Teams`);
  formdata.append("file_content", dataDemo[0]);

  try {
    const rawResponse = await axios({
      url: `${APIURL}/api/demo/consume_uploaded_files_datamicroservice`,
      method: "POST",
      data: formdata,
    });

    return rawResponse.data;
  } catch (error: any) {
    throw error;
  }
};

export const sendEmailContact = async (form: FormContact) => {
  const body = {
    name: form.name,
    correo: form.email,
    subject: form.subject,
    message: form.message,
  };

  try {
    const rawResponse = await axios({
      url: `${APIURL}/api/usuarios/contact_email`,
      method: "POST",
      data: body,
    });

    return rawResponse.data;
  } catch (error: any) {
    throw error;
  }
};
