import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { displayLoader, removeProcess } from "./loading";
import { AppThunk } from "store/store";
import * as Api from "pages/users/api/api";
import {
  ResponseUsersCompany,
  UsersCompany,
  UsersTable,
} from "pages/users/model";
import { FormRegisterType } from "pages/register/model";

type InitialState = {
  isReloadNeeded: boolean;
  usersCompany: UsersCompany[];
  tableUsers: UsersTable[];
  showModalNewUsers: boolean;
  userSelected?: FormRegisterType;
  userPersonSelected?: UsersCompany;
  idActionUser: number;
};
const initialState: InitialState = {
  isReloadNeeded: true,
  usersCompany: [],
  tableUsers: [],
  showModalNewUsers: false,
  userSelected: undefined,
  userPersonSelected: undefined,
  idActionUser: 1,
};
export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersInit: (state) => {
      state.isReloadNeeded = true;
    },
    setUsersSucess: (state, action: PayloadAction<UsersCompany[]>) => {
      state.tableUsers = action.payload.map((user) => ({
        id: user.uid,
        name: user.name_in_meeting_platform,
        email: user.email,
      }));
      state.usersCompany = action.payload;
      state.isReloadNeeded = false;
    },
    setUsersError: (state) => {
      state.isReloadNeeded = false;
    },
    setShowModalNewUsers: (state, action: PayloadAction<boolean>) => {
      state.showModalNewUsers = action.payload;
      if (!action.payload) {
        state.userSelected = undefined;
        state.userPersonSelected = undefined;
        state.idActionUser = 1;
      }
    },
    setUserSelected: (state, action: PayloadAction<UsersTable>) => {
      const findUser = state.usersCompany.find(
        (item) => item.uid === action.payload.id
      );
      if (!findUser) return;

      state.userPersonSelected = findUser;

      state.userSelected = {
        name: findUser.id_person.name,
        id_identification_type: findUser.id_person.id_identification_type,
        identification_number: parseInt(
          findUser.id_person.identification_number
        ),
        phone_number: parseInt(findUser.id_person.phone_number),
        email: findUser.email,
        meetingName: findUser.name_in_meeting_platform,
        password: "",
        confirmPassword: "",
        uid: findUser.uid,
        status: findUser.status,
        photo: findUser.photo ? findUser.photo : ""
      };
    },
    setIdActionUser: (state, action: PayloadAction<number>) => {
      state.idActionUser = action.payload;
    },
  },
});
export const { setShowModalNewUsers, setUserSelected, setIdActionUser } =
  slice.actions;

export default slice.reducer;

export const getUserFromCompany =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      dispatch(slice.actions.setUsersInit());
      const { token } = getState().auth.dataUser!;
      const response: ResponseUsersCompany = await Api.getUsersFromCompany(
        token
      );
      dispatch(removeProcess(idProcess));
      dispatch(slice.actions.setUsersSucess(response.users));
      return true;
    } catch (error) {
      dispatch(slice.actions.setUsersError());
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getUserFromCompanyWithOutReload =
  (): AppThunk => async (dispatch, getState) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getState().auth.dataUser!;
      const response: ResponseUsersCompany = await Api.getUsersFromCompany(
        token
      );
      dispatch(removeProcess(idProcess));
      dispatch(slice.actions.setUsersSucess(response.users));
      return true;
    } catch (error) {
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
