import React, { useEffect } from "react";
import { columnsGantt } from "./constants";
import { GanttChartProps } from "./model";

const GanttChart = (props: GanttChartProps) => {
  const { rows } = props;

  const options = {
    height: rows.length * 50,
    width:900,
    gantt: {
      
      trackHeight: 30,
      labelStyle: {
        fontName: "Poppins",
        fontSize: 10,
        color: "#2F0084"
      },
      palette: [
        {
          "color": "#2F0084",
          "dark": "#2F0084",
          "light": "#2F0084"
        },
      ]
    }
  };


  useEffect(() => {
    const loadGoogleCharts = () => {
      window.google.charts.load('current', { packages: ['gantt'] });
      window.google.charts.setOnLoadCallback(drawChart);
    };

    function daysToMilliseconds(days:any) {
      return days * 24 * 60 * 60 * 1000;
    }
    const drawChart = () => {
      const data = new window.google.visualization.DataTable();
      columnsGantt.map((item) => { data.addColumn(item.type, item.label); })
      data.addRows(rows);
      // data.addRows([
      //   ['Research', 'Find sources',null,
      //    new Date(2015, 0, 1), new Date(2015, 0, 5), null,  100,  null],
      //   ['Write', 'Write paper',
      //     null,null, new Date(2015, 0, 9), daysToMilliseconds(3), 25, 'Research,Outline'],
      //   ['Cite', 'Create bibliography',
      //     null,null, new Date(2015, 0, 7), daysToMilliseconds(1), 20, 'Research'],
      //   ['Complete', 'Hand in paper',
      //     null,null, new Date(2015, 0, 10), daysToMilliseconds(1), 0, 'Cite,Write'],
      //   ['Outline', 'Outline paper',
      //     null, null, new Date(2015, 0, 6), daysToMilliseconds(1), 100, 'Research']
      // ]);
      const chart = new window.google.visualization.Gantt(document.getElementById('chart_div') as HTMLElement);
      chart.draw(data, options);
    };


    if (!(window as any).google) {
      const script = document.createElement('script');
      script.src = 'https://www.gstatic.com/charts/loader.js';
      script.onload = loadGoogleCharts;
      document.body.appendChild(script);
    } else {
      loadGoogleCharts();
    }
  }, []);

  return (
    <div >
      <div id="chart_div"></div>
    </div>
  );
};

export default GanttChart;
